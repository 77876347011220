import _expressSession from "express-session";
import _cookieParser from "cookie-parser";
import _client from "utilise/client";
import _noop from "utilise/noop";
import _key from "utilise/key";
import _log from "utilise/log";
var exports = {};

// -------------------------------------------
// Populates sessionID on each connection
// -------------------------------------------
exports = function (ripple, {
  session
} = {}) {
  log("creating");
  if (!session || !ripple.server) return ripple;
  ripple.server.express.use(cookies(session.secret)).use(sessions(session));
  ripple.server.ws.on("connection", auth(session));
  return ripple;
};

const sessions = _expressSession,
      cookies = _cookieParser,
      client = _client,
      noop = _noop,
      key = _key,
      log = _log("[ri/sessions]"),
      auth = ({
  secret,
  name
}) => socket => {
  const req = {};
  key("headers.cookie", socket.upgradeReq.headers.cookie)(req);
  cookies(secret)(req, null, noop);
  socket.sessionID = req.signedCookies[name] || req.cookies[name];
};

export default exports;